export function maskCPF(cpf: string): string {
  return cpf
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export function maskZipCode(cep: string): string {
  return cep.replace(/\D/g, '').replace(/(\d{5})(\d{3})$/, '$1-$2')
}

export function maskCNPJ(cnpj: string) {
  const CNPJ_PATTERN = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/

  return cnpj?.replace(CNPJ_PATTERN, '$1.$2.$3/$4-$5') ?? ''
}

export function maskPhone(phone: string): string {
  return phone
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)?$/, '$1-$2')
}

export function maskDate(date: string): string {
  return date
    .slice(0, 10)
    .replace(/\D/g, '')
    .replace(/^(\d{0,2})(\d{0,2})?(\d{0,4})?/, (_, p1, p2, p3) => {
      let result = ''
      if (p1) result += p1
      if (p2) result += `-${p2}`
      if (p3) result += `-${p3}`
      return result
    })
}

export function onlyNumbers(value: string): string {
  return value.replace(/\D/g, '')
}

const CPF_PATTERN = /^\d{11}$/

const calcDigit = (value: number) => {
  const digit = value % 11
  if (digit === 10) {
    return 0
  }
  return digit
}

export function validateCPF(cpf: string) {
  if (
    !CPF_PATTERN.test(cpf) ||
    [...cpf].filter((e) => e !== cpf[0]).length === 0
  ) {
    return false
  }

  const mutable_cpfArray = [...cpf]
    .slice(0, 9)
    .map((v) => Number.parseInt(v, 10))
  const firstDigit = calcDigit(
    mutable_cpfArray.reduce((a, v, i) => v * (i + 1) + a),
  )
  mutable_cpfArray.push(firstDigit)
  const secondDigit = calcDigit(
    mutable_cpfArray.reduce((a, v, i) => i * v + a, 0),
  )
  mutable_cpfArray.push(secondDigit)
  return mutable_cpfArray.join('') === cpf
}

export function validateCNPJ(cnpj: string): boolean {
  const cnpjDigits = onlyNumbers(cnpj)

  if (!cnpjDigits || cnpjDigits.length !== 14) {
    return false
  }

  const calcDigit = (value: number) => {
    const digit = value % 11
    return digit < 2 ? 0 : 11 - digit
  }

  const firstDigit = calcDigit(
    Number(cnpjDigits.charAt(0)) * 5 +
      Number(cnpjDigits.charAt(1)) * 4 +
      Number(cnpjDigits.charAt(2)) * 3 +
      Number(cnpjDigits.charAt(3)) * 2 +
      Number(cnpjDigits.charAt(4)) * 9 +
      Number(cnpjDigits.charAt(5)) * 8 +
      Number(cnpjDigits.charAt(6)) * 7 +
      Number(cnpjDigits.charAt(7)) * 6 +
      Number(cnpjDigits.charAt(8)) * 5 +
      Number(cnpjDigits.charAt(9)) * 4 +
      Number(cnpjDigits.charAt(10)) * 3 +
      Number(cnpjDigits.charAt(11)) * 2,
  )

  const secondDigit = calcDigit(
    Number(cnpjDigits.charAt(0)) * 6 +
      Number(cnpjDigits.charAt(1)) * 5 +
      Number(cnpjDigits.charAt(2)) * 4 +
      Number(cnpjDigits.charAt(3)) * 3 +
      Number(cnpjDigits.charAt(4)) * 2 +
      Number(cnpjDigits.charAt(5)) * 9 +
      Number(cnpjDigits.charAt(6)) * 8 +
      Number(cnpjDigits.charAt(7)) * 7 +
      Number(cnpjDigits.charAt(8)) * 6 +
      Number(cnpjDigits.charAt(9)) * 5 +
      Number(cnpjDigits.charAt(10)) * 4 +
      Number(cnpjDigits.charAt(11)) * 3 +
      Number(cnpjDigits.charAt(12)) * 2,
  )

  return (
    Number(cnpjDigits.charAt(12)) === firstDigit &&
    Number(cnpjDigits.charAt(13)) === secondDigit
  )
}
